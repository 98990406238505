<template>
  <div class="perfect-data-modal">
    <div class="mask" v-if="showModal" @click="perfectDataShow"></div>
    <div class="pop input-box" v-if="showModal">
      <div class="data-box">
        <img @click="perfectDataShow" class="close-btn" src="../assets/images/register/close.png" alt="">
        <perfect-data-left2 :stepState="stepState"/>
        <div v-if="identityState == 1" class="input-fr">
          <div class="input-fr-box">
            <h2>{{$t('perfectData.addBankTitle1')}}</h2>
            <p class="title-p" >{{$t('perfectData.addBankTitle2')}}</p>
            <div class="from-box scrollbarchange">
              <div class="input-list">
                  <div class="bank-fr" :class="{ thStyle: isthStyle != 0 }">
                      <div class="text-box">
                        <span>{{$t('perfectData.addBankInput6')}}：</span>                 
                      </div>

                      <div class="c-channel-box">
                        <div @click.stop="getAllBankBy" class="c-channel">    
                          <div class="c-channel-data">
                            <p class="name dian1">{{selectBank.bankName}}</p>
                            <input type="hidden" :value="selectBank.bankCode">
                          </div>
                          <img class="xl-icon" src="@/assets/images/transaction/xl-icon.png" alt="">
                        </div>
                        <div v-if="bankChanneState" class="channer-menu scrollbarchange">
                          <div  class="c-channel" v-for="(item,index) in allBankList" :key="index" @click="selectBankChanne(item)">
                            <div class="c-channel-data">
                              <p class="name dian1">{{ item.bankName }}</p>
                              <input type="hidden" :value="item.bankCode">
                            </div>
                          </div>
                        </div> 
                      </div>
                      <p v-if="selectBankNameErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{selectBankNameErrContent}}</span></p>
                  </div> 
                  <div v-if="isthStyle == 0" class="bank-fl">
                      <div class="text-box">
                        <span>{{$t('perfectData.addBankInput5')}}：</span>
                        <div class="tips-btn">
                          <img src="@/assets/images/account/wh-s-icon.png" alt="">
                          <div class="tips-box">
                            <p class="tips-text">{{$t('perfectData.example')}}：</p>
                            <ul class="yh-list-box">
                              <li><span>{{$t('perfectData.addBankInput6')}}</span><span>{{$t('perfectData.addBankInput5')}}</span></li>
                              <li><span>{{$t('perfectData.twBankName1')}}</span><span>013</span></li>
                              <li><span>{{$t('perfectData.twBankName2')}}</span><span>822</span></li>
                              <li><span>{{$t('perfectData.twBankName3')}}</span><span>700</span></li>
                            </ul>
                          </div>
                        </div>                 
                      </div>
                      <input v-model="bankCode" @blur="inputBlur(1)" class="bank-code" type="text" :placeholder="$t('perfectData.example')+'：013'">
                      <p v-if="bankCodeErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{bankCodeErrContent}}</span></p>
                  </div>              
              </div>
                          
              <div v-if="bankNameInput" class="bank-name-box">
                  <input v-model="bankName" @blur="inputBlur(2)" class="bank-name" type="" :placeholder="$t('perfectData.addBankInput2')">
                  <p v-if="bankNameErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{bankNameErrContent}}</span></p>
              </div>                                  
              
              <div class="input-list">              
                  <div class="bank-fr" :class="{ thStyle: isthStyle == 2 }">
                      <div class="text-box">
                        <span>{{$t('perfectData.addBankInput8')}}：</span>                 
                      </div>
                      <input v-model="bankAccount" @blur="inputBlur(3)" class="bank-account" type="text" :placeholder="$t('perfectData.example')+'：'+[isthStyle?'000-0-00000-0':'000-00-000000-0']">
                      <p v-if="bankAccountErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{bankAccountErrContent}}</span></p>
                  </div>
                  <div v-if="isthStyle == 0 || isthStyle == 1" class="bank-fl">
                      <div class="text-box">
                        <span>{{$t('perfectData.addBankInput7')}}：</span>                 
                      </div>
                      <input v-model="branchName" @blur="inputBlur(4)" class="branch-name" type="" :placeholder="$t('perfectData.example')+'：'+[isthStyle?$t('perfectData.thBankData2'):$t('perfectData.twBankData2')]">
                      <p v-if="branchNameErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{branchNameErrContent}}</span></p>
                  </div>                                  
              </div>
              <div class="upload-image-tlt">
                <div class="text-box">
                  <span>{{$t('perfectData.addBankTitle3')}}：</span>
                  <div class="tips-btn">
                    <img src="@/assets/images/account/wh-s-icon.png" alt="">
                    <div class="tips-box">
                      <p class="tips-text">{{$t('perfectData.example')}}：</p>
                      <img v-if="findUserInfo.countryId == 'Thailand'" src="@/assets/images/account/th-bank.png" alt="">
                      <img v-else-if="findUserInfo.countryId == 'Vietnam'" src="@/assets/images/account/vn-bank.png" alt="">
                      <img v-else src="@/assets/images/account/tw-bank.png" alt="">
                    </div>
                  </div>                 
                </div>
              </div>
              <div class="upload-image-content">
                <input @change="uploadBankImage" name="reverseImageFile" type="file" accept="image/png,image/gif,image/jpeg" id="uploadBank" style="display: none;"/>
                <label for="uploadBank">
                  <div class="bank-image">
                      <img v-if="bankImage" class="image-upload" :src="bankImage" />
                      <div class="upload-image-tip">
                          <p class="p2">{{$t('perfectData.addBankTitle4')}}</p>  
                      </div>
                      <img src="../assets/images/register/add-icon.png" class="image-add">                  
                  </div>
                </label> 
                <p v-if="bankImgIdErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{bankImgIdErrContent}}</span></p>
              </div>        
              <input @click="addBankCard" class="input-btn" type="button" :value="$t('perfectData.toVerify')" />
            </div>
          </div>     
        </div>
        <!-- 身份认证成功 -->
        <div v-if="identitySuccessful == 1" class="input-fr">
            <div class="input-fr-box">
                <img class="rz-icon" src="../assets/images/register/rzcg.png" alt="">
                <p class="p1">{{$t('perfectData.authenticationSuccessful')}}</p>
                <p class="p3 p1">{{$t('loginRegister.loginSuccTitle')}}</p>
                <div class="link-box">
                    <a class="link-btn1" href="/deposit">{{$t('loginRegister.loginSuccBtn1')}}</a>
                </div> 
            </div> 
        </div>       
      </div>     
    </div> 
    <!-- 提示框组件 -->
    <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />
  </div>
    

   
  

</template>

<script>
import PerfectDataLeft2 from '@/components/PerfectDataLeft2.vue';
import CountryInput from '@/components/CountryInput.vue';
import EmailInput from '@/components/EmailInput.vue';
import CodeInput from '@/components/CodeInput.vue';
import Dialog from '@/components/Dialog.vue';
import account from '@/api/account.js';
import {Config} from "@/util/const";
export default{
  props:["showModal"],
  components: {
    PerfectDataLeft2,
    EmailInput,
    CodeInput,
    CountryInput,
    Dialog
  },
  data() {
    return {
      isShowConfirm:false,
      identityState:1,
      identitySuccessful:0,
      stepState:false,
      isthStyle:0,
      findUserInfo:[],
      allBankList:[],
      bankChanneState:false,
      selectBank:{
        bankName:this.$i18n.t('loginRegister.checkPrompt27'),
        bankCode:''
      },
      selectBankOther:false,
      bankNameInput:false,
      bankImage:'',
      bankImgId:'',
      bankCode:'',
      bankName:'',
      branchName:'',
      bankAccount:'',
      bankCodeErr:false,
      bankNameErr:false,
      selectBankNameErr:false,
      branchNameErr:false,
      bankAccountErr:false,
      bankImgIdErr:false,
      selectBankNameErrContent:'',
      bankCodeErrContent:'',
      bankNameErrContent:'',
      branchNameErrContent:'',
      bankAccountErrContent:'',
      bankImgIdErrContent:'',
    };
  },
  created() {
    account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              this.findUserInfo = res.data.value; 
              // 调用获取银行数据列表方法
              this.getAllBankByList(this.findUserInfo.countryId);
              if(res.data.value.countryId == "Thailand"){
                this.isthStyle = 1;
              }else if(res.data.value.countryId == "Indonesia" || res.data.value.countryId == "Vietnam"){                      
                this.isthStyle = 2;
              }else{
                this.isthStyle = 0;
              }         
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
      
  },
  methods: {
    // 获取银行数据列表
    getAllBankByList(countryId){
      let data = {
        countryId: countryId,
      }
      account.getAllBankByList(data).then((res)=>{
          if(res.data.success == 1){
            this.allBankList = res.data.value;
          }else{
            console.log(res.data.message);
          }
        }).catch(err=>{
          console.log(err.message);
        })
    },
    // 显示银行下拉列表
    getAllBankBy(){
      this.bankChanneState = !this.bankChanneState;
    },
    // 选择银行名称
    selectBankChanne(channel){
      this.bankChanneState = false;
      this.selectBankNameErr = false;
       this.selectBank = channel;
       this.selectBankOther = true;
      if(channel.id == "app.AllCountries.bank"){
        this.bankNameInput = true;
        this.bankName = '';
        this.bankCode = '';
      }else{
        this.bankNameInput = false;
        this.bankName = channel.bankName;
        this.bankCode = channel.bankCode;
      }     
    },
    // 上传银行账单照片
    uploadBankImage(e){
      var self = this
      let file = e.target.files[0];
      console.log(file.name+"file");
      let param = new FormData(); // 创建form对象
      param.append('file', file, file.name) // 通过append向form对象添加数据
      account.uploadBankImg(param).then((res)=>{
          if(res.data.success == 1){
              this.bankImage = Config.images + res.data.value.realFullName;
              this.bankImgId = res.data.value.id;
              this.bankImgIdErr = false;
          }else{
              console.log(res.data.message+"aaa");
          }
      }).catch(err=>{
        console.log(err.message+"bbb");
      })
    },
    // 鼠标离开输入框校验不能为空
    inputBlur(type){
      if(type == 1){
        if(!this.bankCode){
          this.bankCodeErr = true;
          this.bankCodeErrContent = this.$i18n.t('loginRegister.checkPrompt17');          
        }else{
          this.bankCodeErr = false;
        }
      }
      if(type == 2){
        if(!this.bankName){
          this.bankNameErr = true;
          this.bankNameErrContent = this.$i18n.t('loginRegister.checkPrompt19');       
        }else{
          this.bankCodeErr = false;
        }
      }
      if(type == 3){
        if(!this.bankAccount){
          this.bankAccountErr = true;
          this.bankAccountErrContent = this.$i18n.t('loginRegister.checkPrompt20');
        }else{
          this.bankAccountErr = false;
        }
      }
      if(type == 4){
        if(!this.branchName){
          this.branchNameErr = true;
          this.branchNameErrContent = this.$i18n.t('loginRegister.checkPrompt18'); 
        }else{
            this.branchNameErr = false;
        }
      }
    },
    // 去验证，提交银行信息
    addBankCard(){
      if(!this.selectBankOther){
        this.selectBankNameErr = true;
        this.selectBankNameErrContent = this.$i18n.t('loginRegister.checkPrompt19');
        return;
      }
      if(this.isthStyle == 0){
        if(!this.bankCode){
          this.selectBankNameErr = false;
          this.bankCodeErr = true;
          this.bankCodeErrContent = this.$i18n.t('loginRegister.checkPrompt17'); 
          return;
        }
      }
      if(!this.bankName){
        this.selectBankNameErr = false;
        this.bankCodeErr = false;
        this.bankNameErr = true;
        this.bankNameErrContent = this.$i18n.t('loginRegister.checkPrompt19');
        return;
      }
      if(!this.bankAccount){
        this.selectBankNameErr = false;
        this.bankNameErr = false;
        this.bankCodeErr = false;
        this.bankAccountErr = true;
        this.bankAccountErrContent = this.$i18n.t('loginRegister.checkPrompt20');
        return;
      }
      if(this.isthStyle == 0 || this.isthStyle == 1){
        if(!this.branchName){
          this.selectBankNameErr = false;
          this.bankAccountErr = false;
          this.branchNameErr = true;
          this.branchNameErrContent = this.$i18n.t('loginRegister.checkPrompt18'); 
          return;
        }
      }  
      if(!this.bankImgId){
        this.selectBankNameErr = false;
        this.branchNameErr = false;
        this.bankImgIdErr = true;
        this.bankImgIdErrContent = this.$i18n.t('loginRegister.checkPrompt21');
        return;
      }
      let data = {
        bankCardImg: this.bankImgId,
        bankCode: this.bankCode,
        bankName: this.bankName,
        bankBranch: this.branchName,
        bankCardNo: this.bankAccount,
      }
      account.updateBankCardInfo(data).then((res)=>{
          if(res.data.success == 1){
              this.identityState = 0;
              this.identitySuccessful = 1;
              this.stepState = true;
              this.bankImgIdErr = false;
          }else{
            this.bankImgIdErr = true;
            this.bankImgIdErrContent = res.data.message;
              console.log(res.data.message+"aaa");
          }
      }).catch(err=>{
        this.bankImgIdErr = true;
        this.bankImgIdErrContent = err.message;
        console.log(err.message+"bbb");
      })
      
    },
    
    // 关闭弹窗事件传递给父组件
    perfectDataShow(){
      const popTitle = this.$i18n.t('perfectData.popPrompt');
      const cancel = this.$i18n.t('perfectData.cancel');
      const confirm = this.$i18n.t('perfectData.continue');
      const title = this.$i18n.t('accountCenter.reminder');
      this.isShowConfirm = true
      this.$refs.myConfirm.show(popTitle, {
          type: 'confirm',
          cancelText:cancel,
          confirmText: confirm,
          titleText: title,
          data: '我是外界传进来的数据'
      })
    },
    // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
      if(type === "clickCancel"){
        that.$router.push("./");//去账户首页
        this.isShowConfirm = false    
      }else if(type === "clickConfirm"){
        // that.$router.push("./");
        this.isShowConfirm = false    
      }          
    }, 
    bankChanneShow(){   
        this.bankChanneState = false;
    },
  },
  mounted () {
    document.addEventListener('click', this.bankChanneShow)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /* .perfect-data-modal{height:628px;margin-top: 70px;} */
  .mask {background-color: #000;opacity: 0.3;position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9}
  .pop {background-color: #fff;position: absolute;top: 75px;left: 50%;margin-left: -516px; z-index: 99;}
  .data-box{width: 100%;height: 100%;position: relative;display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
  .close-btn {width: 22px;height: 22px; position: absolute;top: 30px;right: 30px;cursor: pointer;}
  .pop .input-btn{width: 382px;margin-bottom: 0;position: absolute;bottom:55px;}
  .from-box{margin-top: 15px; width: 100%;height: 425px;padding-right: 10px;overflow-y: scroll;}
  .from-box .input-list-1{width: 100%;height: 55px;margin-bottom: 28px;border-radius: 5px;border: 1px solid #e0e0e0;position: relative;}
  .from-box .input-list{width: 100%;height: 75px;margin-bottom: 28px;display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
  .from-box .input-list-1 .acc-name{width: 92%;line-height: 55px;height: 55px; font-size: 14px;padding: 0 10px; color: #4c4c4c;}
  .from-box .input-list .bank-fl{width: 42%;height: 75px;position: relative;}

  .input-list .c-channel-box{position: relative;width: 90%;height: 48px;line-height: 48px;padding: 0 10px;border: 1px solid #e0e0e0;border-radius: 5px;cursor: pointer;}
  .input-list .c-channel-data{width: 100%;}
  .input-list .c-channel{width: 100%;height: 100%;position: relative;cursor: pointer; border-radius: 5px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .input-list .c-channel .rjfs-icon{width: 35px;height: 22px;margin: 0 16px;}
  .input-list .c-channel .name{font-size: 15px;color: #4c4c4c;width: 99%;}
  .input-list .c-channel .xl-icon{width: 14px;height: 9px;position: absolute;top: 20px;right: 0;}
  .input-list .c-channel-box .channer-menu{width: 100%;height:150px;overflow-y: scroll;background: #fff; position:absolute;top: 52px;left: -1px;z-index: 10;border: 1px solid #e0e0e0;border-radius: 5px;}
  .input-list .channer-menu .c-channel{width: 90%;padding: 0 5%; height: 35px;line-height:35px; margin: auto; border: none;border-bottom: 1px solid #f2f2f2;border-radius: 0;}
  .input-list .channer-menu .c-channel .menu-channel{margin-left: 0;border: 1px solid #f2f2f2;}
  .input-list .channer-menu .c-channel:hover{background: #f2f2f2;}
  .input-list .channer-menu .c-channel:last-child{border: none;}
  
  .from-box .input-list .text-box{margin-bottom: 5px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .input-list .text-box span{font-size: 14px;color: #000;}
  .from-box .input-list .tips-btn{width: 16px;height: 16px;position: relative;}
  .from-box .input-list .tips-btn:hover .tips-box{display: block;}
  .from-box .input-list .tips-box{width: 209px; position: absolute;top: -8px;left: -138px;padding: 10px;border-radius: 10px;z-index: 10; background: #333; display: none;}
  .from-box .input-list .tips-box .tips-text{font-size: 12px;line-height: 20px;margin-bottom: 5px; color: #fff;text-align: left;}
  .from-box .input-list .tips-box .yh-list-box{width: 209px;background: #fff;}
  .from-box .input-list .tips-box .yh-list-box li{display: flex;}
  .from-box .input-list .tips-box .yh-list-box li span{flex: 2;text-align: center;padding: 8px 5px;}
  .from-box .input-list .tips-box .yh-list-box li:first-child span{background: #e6e6e6;}
  .from-box .input-list .bank-fl input{width: 86%;line-height: 48px;height: 48px; font-size: 14px;padding: 0 10px;border-radius: 5px;border: 1px solid #e0e0e0; color: #4c4c4c;}

  .from-box .input-list .bank-fr{width: 52%;height: 75px;position: relative;}
  .from-box .bank-name-box{width: 100%;height: 75px;position: relative;}
  .from-box .bank-name-box input{width: 94%;line-height: 48px;height: 48px;font-size: 14px;padding: 0 10px;border-radius: 5px;border: 1px solid #e0e0e0; color: #4c4c4c;}
  .from-box .bank-name-box .err-text{font-size: 12px;line-height: 10px; color:#fa885b;position:absolute;bottom: 8px;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .bank-name-box .err-text img{width: 12px;height: 12px;margin-right: 5px;}
  .from-box .input-list .thStyle{width: 100%;}
  .from-box .input-list .thStyle .c-channel-box{width: 94%;}
  .from-box .input-list .bank-fr input{width: 90%;line-height: 48px;height: 48px;font-size: 14px;padding: 0 10px;border-radius: 5px;border: 1px solid #e0e0e0; color: #4c4c4c;}
  .from-box .input-list .thStyle input{width: 94%;}
  
  .from-box .input-list-1 .err-text{font-size: 14px;line-height: 14px; color:#fa885b;position:absolute;bottom: -22px;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .input-list-1 .err-text img{width: 14px;height: 14px;margin-right: 5px;}
  .from-box .input-list .err-text{font-size: 12px;line-height: 10px; color:#fa885b;position:absolute;bottom: -16px;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .input-list .err-text img{width: 12px;height: 12px;margin-right: 5px;}

  .from-box .upload-image-tlt{width: 100%;margin-bottom: 5px;}
  .from-box .upload-image-tlt .text-box{margin-bottom: 5px; display: flex;flex-direction: row;justify-content: flex-start;align-items: flex-start;}
  .from-box .upload-image-tlt .text-box span{font-size: 14px;color: #000;}
  .from-box .upload-image-tlt .tips-btn{width: 16px;height: 16px;position: relative;}
  .from-box .upload-image-tlt .tips-btn:hover .tips-box{display: block;}
  .from-box .upload-image-tlt .tips-box{width: 215px;height:175px; position: absolute;top: -120px;left: -238px;padding: 10px;border-radius: 10px;z-index: 10; background: #333; display: none;}
  .from-box .upload-image-tlt .tips-box .tips-text{font-size: 12px;line-height: 20px;margin-bottom: 5px; color: #fff;text-align: left;}
  .from-box .upload-image-tlt .tips-box img{width: 215px;height:145px;}

  .from-box .upload-image-content{width: 100%;position: relative;margin: auto;margin-bottom: 90px;}
  .from-box .upload-image-content .err-text{font-size: 14px;line-height: 14px;color: #fa885b;position: absolute;bottom: -22px;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .upload-image-content .err-text img{width: 14px;height: 14px;margin-right: 5px;}
  .from-box .upload-image-content .bank-image{width: 100%;height: 170px;margin-bottom: 30px;position: relative; border: 1px dashed #e0e0e0;border-radius: 8px;cursor: pointer;}
  .from-box .upload-image-content .image-upload{width: 100%;height: 100%;position: absolute;top: 0;left: 0;border: none;}
  .from-box .upload-image-content .image-add{width: 30px;height: 30px;position: absolute;top: 50%;left: 50%;margin-left: -15px;}
  .from-box .upload-image-content .upload-image-tip{width: 100%; position: absolute;top: 12%;left: 0;}
  .from-box .upload-image-content .upload-image-tip .p1{font-size: 14px;color: #4c4c4c;text-align: center;margin-bottom: 5px;}
  .from-box .upload-image-content .upload-image-tip .p2{font-size: 12px;color: #b2b2b2;text-align: center;margin-bottom: 5px;}

@media screen and (max-width: 768px) {
  /* .perfect-data-modal{height:100%;margin-top: 3rem;} */
  .mask {background-color: #000;opacity: 0.3;position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9}
  .pop {position: fixed;top: 3rem;left: 50%;margin-left: -45%; height: 75%; z-index: 99;background-color: #fff;}
  .data-box{width: 100%;height: 100%;position: relative;display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
  .close-btn {width: 1.2rem;height: 1.2rem; position: absolute;top: 1rem;right: 1rem;cursor: pointer;}
  .pop .input-btn{width: 90%; margin-bottom: 0;position: absolute;bottom:2.5rem;}
  .from-box{padding-top: 0.5rem;margin-top: 1rem; width: 100%;height: 16.5rem;padding-right: 0.5rem;overflow-y: scroll;}
  .from-box .input-list-1{width: 100%;height: 2.5rem;margin-bottom: 1.4rem;border-radius: 5px;border: 1px solid #e0e0e0;position: relative;}
  .from-box .input-list{width: 100%;height: 3.9rem;margin-bottom: 1.4rem;display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
  .from-box .input-list-1 .acc-name{width: 92%;line-height: 2.5rem;height: 2.5rem; font-size: 0.6rem;padding: 0 0.5rem; color: #4c4c4c;}
  .from-box .input-list .bank-fl{width: 42%;height:3.9rem;position: relative;}

  .input-list .c-channel-box{position: relative;width: 90%;height: 2.5rem;line-height: 2.5rem;padding: 0 0.5rem;border: 1px solid #e0e0e0;border-radius: 5px;cursor: pointer;}
  .input-list .c-channel-data{width: 100%;}
  .input-list .c-channel{width: 100%;height: 100%;position: relative;cursor: pointer; border-radius: 5px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .input-list .c-channel .rjfs-icon{width: 0.7rem;height: 0.5rem;margin: 0 0.5rem;}
  .input-list .c-channel .name{font-size: 0.6rem;color: #4c4c4c;width: 94%;}
  .input-list .c-channel .xl-icon{width: 0.7rem;height: 0.5rem;position: absolute;top: 1rem;right: 0;}
  .input-list .c-channel-box .channer-menu{width: 100%;height:8rem;overflow-y: scroll; background: #fff; position:absolute;top: 2.8rem;left: -1px;z-index: 10;border: 1px solid #e0e0e0;border-radius: 5px;}
  .input-list .channer-menu .c-channel{width: 90%;padding: 0 5%; height: 2rem;line-height:2rem; margin: auto; border: none;border-bottom: 1px solid #f2f2f2;border-radius: 0;}
  .input-list .channer-menu .c-channel .menu-channel{margin-left: 0;border: 1px solid #f2f2f2;}
  .input-list .channer-menu .c-channel:hover{background: #f2f2f2;}
  .input-list .channer-menu .c-channel:last-child{border: none;}

  .from-box .input-list .text-box{margin-bottom: 0.5rem; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .input-list .text-box span{font-size: 0.6rem;color: #000;}
  .from-box .input-list .tips-btn{width: 0.8rem;height: 0.8rem;position: relative;}
  .from-box .input-list .tips-btn:hover .tips-box{display: block;}
  .from-box .input-list .tips-box{width: 209px; position: absolute;top: 0.3rem;left: -7.3rem;padding: 0.5rem;border-radius: 0.4rem;z-index: 10; background: #333; display: none;}
  .from-box .input-list .tips-box .tips-text{font-size: 0.6rem;line-height: 1rem;margin-bottom: 0.2rem; color: #fff;text-align: left;}
  .from-box .input-list .tips-box .yh-list-box{width: 209px;background: #fff;}
  .from-box .input-list .tips-box .yh-list-box li{display: flex;}
  .from-box .input-list .tips-box .yh-list-box li span{flex: 2;text-align: center;padding: 0.35rem 0.25rem;}
  .from-box .input-list .tips-box .yh-list-box li:first-child span{background: #e6e6e6;}
  
  .from-box .input-list .bank-fl input{width: 86%;line-height: 2.5rem;height: 2.5rem; font-size: 0.6rem;padding: 0 0.5rem;border-radius: 5px;border: 1px solid #e0e0e0; color: #4c4c4c;}
  .from-box .input-list .bank-fr{width: 52%;height: 3.9rem;position: relative;}
  .from-box .bank-name-box{width: 100%;height: 3.9rem;position: relative;}
  .from-box .bank-name-box input{width: 96%;line-height: 2.5rem;height: 2.5rem;font-size: 0.6rem;padding: 0 0.5rem;border-radius: 5px;border: 1px solid #e0e0e0; color: #4c4c4c;}
  .from-box .bank-name-box .err-text{font-size: 0.6rem;line-height: 0.6rem; color:#fa885b;position:absolute;bottom: 8px;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .bank-name-box .err-text img{width: 0.6rem;height: 0.6rem;margin-right: 5px;}
  .from-box .input-list .thStyle{width: 100%;}
  .from-box .input-list .thStyle .c-channel-box{width: 94%;}
  .from-box .input-list .bank-fr input{width: 90%;line-height: 2.5rem;height: 2.5rem;font-size: 0.6rem;padding: 0 0.35rem;border-radius: 5px;border: 1px solid #e0e0e0; color: #4c4c4c;}
  .from-box .input-list .thStyle input{width: 94%;}
  .from-box .input-list-1 .err-text{font-size: 0.6rem;line-height: 0.6rem; color:#fa885b;position:absolute;bottom: -0.9rem;left: 0.3rem;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .input-list-1 .err-text img{width: 0.6rem;height: 0.6rem;margin-right: 5px;}
  .from-box .input-list .err-text{font-size: 0.6rem;line-height: 0.6rem; color:#fa885b;position:absolute;bottom: -1rem;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .input-list .err-text img{width: 0.6rem;height: 0.6rem;margin-right: 5px;}

  .from-box .upload-image-tlt{width: 100%;margin-bottom: 0.5rem;}
  .from-box .upload-image-tlt .text-box{margin-bottom: 0.25rem; display: flex;flex-direction: row;justify-content: flex-start;align-items: flex-start;}
  .from-box .upload-image-tlt .text-box span{font-size: 0.6rem;color: #000;}
  .from-box .upload-image-tlt .tips-btn{width: 0.8rem;height: 0.8rem;position: relative;}
  .from-box .upload-image-tlt .tips-btn:hover .tips-box{display: block;}
  .from-box .upload-image-tlt .tips-box{width: 9rem;height:6.5rem; position: absolute;top: -6rem;left: -10.4rem;padding: 0.5rem;border-radius: 0.4rem;z-index: 10; background: #333; display: none;}
  .from-box .upload-image-tlt .tips-box .tips-text{font-size: 0.6rem;line-height: 1rem;margin-bottom: 0.2rem; color: #fff;text-align: left;}
  .from-box .upload-image-tlt .tips-box img{width: 9rem;height:5rem;}

  .from-box .upload-image-content{width: 100%;position: relative; margin: auto;margin-bottom: 3.8rem;}
  .from-box .upload-image-content .err-text{font-size: 0.6rem;line-height: 0.6rem;color: #fa885b;position: absolute;bottom: -0.9rem;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
  .from-box .upload-image-content .err-text img{width: 0.6rem;height: 0.6rem;margin-right: 5px;}
  .from-box .upload-image-content .bank-image{width: 100%;height: 10rem;margin-bottom: 1rem;position: relative; border: 1px dashed #e0e0e0;border-radius: 0.6rem;cursor: pointer;}
  .from-box .upload-image-content .image-upload{width: 100%;height: 100%;position: absolute;top: 0;left: 0;border: none;}
  .from-box .upload-image-content .image-add{width: 1.5rem;height: 1.5rem;position: absolute;top: 50%;left: 50%;margin-left: -0.75rem;}
  .from-box .upload-image-content .upload-image-tip{width: 100%; position: absolute;top: 12%;left: 0;}
  .from-box .upload-image-content .upload-image-tip .p1{font-size: 0.7rem;width: 96%;margin: auto; color: #4c4c4c;text-align: center;margin-bottom: 0.3rem;}
  .from-box .upload-image-content .upload-image-tip .p2{font-size: 0.6rem;width: 96%;margin: auto;color: #b2b2b2;text-align: center;margin-bottom: 0.3rem;}

  }
</style>
